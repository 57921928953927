@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  font-family: "Archivo";
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
p {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

textarea,
input {
  border: none;
  outline: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 2px;
  background-color: #161C2D;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}

ul {
  padding-left: 24px;
}