:root {
  --white-color: #ffffff;
  --grey-color: #8c8c8c;
  --yellow-color: #ffee00;
  --yellow-color-hover: #e4d503;
  --dark-color: #000000;
  --dark-secondary-color: #161c2d;
  --dark-transparent-color: rgba(14, 14, 14, 0.4);
  --red-color: #c93535;
}

.stripe-form-discount {
  position: relative;
  width: 100%;
  background-color: white;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
}

.stripe-form-discount-input {
  width: calc(100% - 120px);
  height: 100%;
  padding: 0 16px;
  font-size: 16px;
}

.stripe-form-discount-input.discount-applied {
  color: var(--grey-color);
}

.stripe-form-discount-input.discount-error {
  color: var(--red-color);
}

.stripe-form-discount-button {
  color: var(--dark-color);
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  background-color: var(--yellow-color);
  height: 36px;
  padding: 0 12px;
  border-radius: 8px;
  transition: 0.2s;
}

.stripe-form-discount-button:hover {
  background-color: var(--yellow-color-hover);
  transition: 0.2s;
}

.stripe-form-discount-applied {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-color);
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
}

.application-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--dark-secondary-color);
}

.home-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: var(--dark-secondary-color);
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: var(--dark-secondary-color);
}

.header__logo {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.logo__text {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.007em;
  color: var(--white-color);
}

.header_buttons {
  display: flex;
  align-items: center;
}

.button__login {
  min-width: 100px;
  height: 40px;
  background-color: transparent;
  border: 1px solid var(--white-color);
  border-radius: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.007em;
  color: var(--white-color);
}

.button__create-account {
  width: 180px;
  height: 40px;
  background: #FFEE00;
  border: 1px solid #FFEE00;
  border-radius: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #161C2D;
  transition: 0.2s;
  margin-left: 10px;
}

.button__create-account:hover {
  background: var(--yellow-color-hover);
  transition: 0.2s;
}

.introduction-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 80px 60px;
  background-color: var(--dark-secondary-color);
}

.text-yellow {
  color: var(--yellow-color);
}

.introduction__heading {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.introduction__description {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #8C8C8C;
}

.introduction__button {
  min-width: 260px;
  height: 60px;
  background: #FFEE00;
  border-radius: 16px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #161C2D;
  margin-top: 16px;
  transition: 0.2s;
  cursor: pointer;
  z-index: 5;
  position: relative;
}

.introduction__button:hover {
  background: var(--yellow-color-hover);
  transition: 0.2s;
}

.introduction__robot {
  position: relative;
  height: 280px;
}

.introduction__robot img {
  position: absolute;
  bottom: -220px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.youtube-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
  background-color: var(--yellow-color);
  padding: 100px 24px;
  width: 100%;
}

.robot__shadow {
  position: absolute;
  top: 120%;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 0px 0px 250px 320px rgba(255, 246, 28, 0.17);
  border-radius: 100%;
}

.youtube__text-block {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  width: fit-content;
}

.youtube__title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #0E0E0E;
}

.youtube__description {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #8C8C8C;
  margin-top: -12px;
}

.youtube__button {
  min-width: 200px;
  height: 60px;
  background: #161C2D;
  border-radius: 16px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #FFFFFF;
  padding: 8px 16px;
  width: fit-content;
  margin-top: 8px;
  transition: 0.2s;
}

.youtube__button:hover {
  background: #0E0E0E;
  transition: 0.2s;
}

.youtube__video-block {
  width: fit-content;
}

.youtube__video-container {
  width: 450px;
  height: 300px;
  border-radius: 24px;
  background-color: #161C2D;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.why-summarie-wrapper {
  background-color: rgba(14, 14, 14, 0.85);
  width: 100%;
}

.why-summarie__web-background {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  padding: 100px 24px;
  position: relative;
}

.why-summarie__web-background::before {
  content: '';
  background-image: url('./assets/web.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.35;
}

.why-summarie__text-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.why-summarie__title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.why-summarie__description {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #8C8C8C;
}

.why-summarie__benefits {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.why-summarie__benefit {
  width: 350px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 10px;
  border-radius: 16px;
  background: rgba(22, 28, 45, 0.4);
  backdrop-filter: blur(1px);
  border: 1px solid #8C8C8C;
}

.benefit__number {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #FFEE00;
}

.benefit__text {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.plans-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  background-color: #FFEE00;
  padding: 80px 24px;
}

.plans__text-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.plans__title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 0.02em;
  color: #161C2D;
}

.plans__description {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #8C8C8C;
}

.plans__list {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.plan-item-wrapper {
  width: 435px;
  background: #161C2D;
  border-radius: 8px;
  padding: 40px;
}

.plan-item__name {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.55px;
  color: #FFFFFF;
}

.plan-item__price {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.55px;
  color: #FFEE00;
  margin-top: 15px;
}

.plan-item__description {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #FFFFFF;
  margin-top: 25px;
}

.plan-item__underline {
  display: block;
  width: 100%;
  height: 1px;
  background: #8C8C8C;
  margin: 40px 0;
}

.plan-item__options {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.plan-item__option {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #8C8C8C;
  display: flex;
  align-items: center;
  gap: 8px;
}

.plan-item__button {
  width: 163px;
  height: 50px;
  border-radius: 8px;
  background: #FFEE00;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #000000;
  transition: 0.2s;
}

.plan-item__button:hover {
  background: #e4d503;
  transition: 0.2s;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  gap: 40px;
  background: #161C2D;
}

.footer__top-info {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.footer-top__links {
  display: flex;
  align-items: center;
  gap: 24px;
}

.footer__link {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #FFFFFF;
  text-decoration: none;
}

.footer-top__socials {
  display: flex;
  align-items: center;
  gap: 24px;
}

.footer__bottom-copyright {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #8c8c8c;
}

.checkout-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.checkout__form {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: 80px;
  padding: 100px 60px;
  background: #161C2D;
  max-width: 1200px;
  margin: 0 auto;
}

.checkout-left-side {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.checkout-left__title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #FFFFFF;
}

.checkout-left__description {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #8C8C8C;
}

.stripe-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
  max-width: 90%;
}

.stripe-button__pay {
  width: 242px;
  height: 60px;
  background: #FFEE00;
  border-radius: 10px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #161C2D;
  transition: 0.2s;
  margin-top: 16px;
}

.stripe-button__pay:disabled {
  background: #8C8C8C;
  transition: 0.2s;
}

.stripe-button__pay:disabled:hover {
  background: #8C8C8C;
  transition: 0.2s;
}

.stripe-button__pay:hover {
  background: #e4d503;
  transition: 0.2s;
}

.checkout-right-side {
  width: 439px;
  background: #FFEE00;
  border-radius: 24px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: fit-content;
}

.checkout-right__paying {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.checkout-right__you-are-paying {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #161C2D;
}

.checkout-right__price {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 44px;
  color: #161C2D;
}

.checkout-right__payment-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.checkout-right__info-item {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.checkout-right__info-label {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #161C2D;
}

.checkout-right__info-value {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #161C2D;
}

.checkout-right__info-underline {
  display: block;
  width: 100%;
  height: 1px;
  background: #161C2D;
}

.checkout-right__plan-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.checkout-right__options-title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: flex-end;
  color: #000000;
}

.checkout-right__option {
  display: flex;
  align-items: center;
  gap: 16px;
}

.checkout-right__option-text {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #161C2D;
}

.header__profile {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #FFEE00;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.header__profile:hover {
  background: #e4d503;
  transition: 0.2s;
}

.header__profile-icon {
  width: 24px;
}


.profile-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.profile__content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 60px 60px 100px 60px;
  background: #161C2D;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.profile__info-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  padding: 24px;
  background-color: rgba(14, 14, 14, 0.85);
}

.profile-info__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.profile-info__title {
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #FFFFFF;
}

.profile-info__info-row {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile-info__label {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #8C8C8C;
  width: fit-content;
}

.profile-info__value {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #FFFFFF;
  width: fit-content;
}

.profile-info__change-plan {
  width: 121px;
  height: 40px;
  background: #FFEE00;
  border-radius: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.007em;
  color: #161C2D;
  transition: 0.2s;
}

.profile-info__change-plan:hover {
  background: #e4d503;
  transition: 0.2s;
}

.profile__title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #FFFFFF;
}

.checkout__loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  gap: 24px;
}

.spinner {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.spinner::before,
.spinner::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

.spinner::before {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #FFEE00 0%, #161C2D 50%);
  animation: spin .5s infinite linear;
}

.spinner::after {
  width: 85%;
  height: 85%;
  background-color: #161C2D;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.checkout__loading-text {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}

.checkout__error {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background: var(--red-color);
  margin-bottom: 16px;
}

.checkout__error-text {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: #FFFFFF;
}

.checkout__error-icon {
  width: 20px;
  cursor: pointer;
}

.checkout-success {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.checkout-success__content {
  min-height: calc(100vh - 82px);
  background-image: url('./assets/success-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 60px 24px;
}

.checkout-success__text {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #FFFFFF;
}

.checkout-success__button {
  width: 200px;
  height: 50px;
  background: #FFEE00;
  border-radius: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  text-align: center;
  color: #161C2D;
  transition: 0.2s;
  margin-top: 16px;
}

.auth-page-wrapper {
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-template-columns: 400px 1fr;
}

.dark-gradient-box-border-wrapper {
  border-radius: 8px;
  padding: 1px;
  background: radial-gradient(farthest-side at 0px 0px,
      #ffffff99 0%,
      #ffffff20 100%),
    radial-gradient(farthest-side at 100% 70%, #ffffff99 0%, #ffffff20 100%);
  height: fit-content;
}

.dark-gradient-box-background-wrapper {
  background-color: var(--dark-secondary-color);
  border-radius: 8px;
}

.dark-gradient-box-wrapper {
  background: linear-gradient(40deg,
      rgba(196, 196, 196, 0.08),
      rgba(196, 196, 196, 0.08)),
    radial-gradient(90.16% 143.01% at 15.32% 21.04%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.0447917) 77.08%,
      rgba(255, 255, 255, 0) 100%);
  background-blend-mode: overlay, normal;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
}

.auth-page__left {
  background-color: rgba(14, 14, 14, 0.85);
}

.auth-page__left::before {
  content: '';
  background-image: url('./assets/success-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 400px;
  height: 100%;
}

.auth-page__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  height: 100%;
  max-width: 600px;
  padding: 40px 80px;
}

.auth-input-show-hide-eye {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.auth-input {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: var(--white-color);
  background-color: transparent !important;
  width: 100%;
}

.auth-input::placeholder {
  color: var(--grey-color);
}

.auth-input-block-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.auth-input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: var(--grey-color);
}

.auth-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.auth-title {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  color: var(--white-color);
}

.auth-title-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14.33px;
  line-height: 16px;
  color: var(--grey-color);
}

.auth-title-block {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: 40px;
  padding-left: 24px;
  gap: 12px;
}

.auth-button {
  width: 100%;
  height: 50px;
  background: #FFEE00;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #161C2D;
  transition: 0.2s;
  margin-top: 24px;
  transition: 0.2s;
}

.auth-button:hover {
  background: #e4d503;
  transition: 0.2s;
}

.auth-google-button {
  width: fit-content;
}

.auth-or-continue-with-block {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  color: var(--grey-color);
  width: 100%;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 8px;
}

.auth-or-continue-with-block::before {
  content: "";
  display: block;
  height: 1px;
  width: calc(50% - 60px);
  background: linear-gradient(90deg, #d9d9d900, var(--grey-color));
  position: relative;
  top: 6px;
  left: 0%;
}

.auth-or-continue-with-block::after {
  content: "";
  display: block;
  height: 1px;
  width: calc(50% - 60px);
  background: linear-gradient(90deg, var(--grey-color), #d9d9d900);
  position: relative;
  top: -6px;
  left: 68%;
}

.auth-page__title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  color: var(--white-color);
  margin-bottom: 8px;
}

.auth-google__button-wrapper {
  width: 45px;
  margin: 0 auto;
}

.auth-switch__text {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: var(--grey-color);
  text-align: center;
}

.text-yellow-button {
  cursor: pointer;
  color: var(--yellow-color);
}

.auth-forgot-password {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: var(--grey-color);
  cursor: pointer;
  width: 100%;
  text-align: right;
  margin-top: 4px;
}

.auth-page__back {
  display: block;
  width: 30px;
  margin-top: 40px;
  margin-left: 24px;
  cursor: pointer;
  position: relative;
}

.reset-email-sent-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  color: var(--white-color);
}

.profile-info__change-plan:disabled {
  background: #8C8C8C;
}

.profile-info__subscription-canceled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background: var(--red-color);
  margin-top: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: #FFFFFF;
}

.agreement-page-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
}

.agreement__big-title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  color: var(--yellow-color);
}

.agreement-page__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 16px;
  padding-bottom: 40px;
  background: #161C2D;
}

.agreement__effective-date {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--grey-color);
}

.agreement-page__small-gap-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.agreement__default-text {
  font-family: "Archivo";
  font-size: 15px;
  line-height: 17px;
  color: var(--white-color);
  font-weight: 400;
}

.agreement__title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: var(--white-color);
  margin-top: 16px;
}

.agreement__subtitle {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: var(--white-color);
}

.agreement__items-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.agreement-list-item__text {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: var(--white-color);
}

.agreement__yellow-color {
  color: var(--yellow-color);
}

.contact-us-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.contact-us-form {
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto;
  padding: 40px 24px;
}

.contact-us-title {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;
  color: var(--white-color);
  margin-bottom: 8px;
}

.contact-us__submit {
  width: 100%;
  height: 50px;
  background: #FFEE00;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #161C2D;
  transition: 0.2s;
  margin-top: 16px;
}

.contact-us__submit:hover {
  background: #e4d503;
  transition: 0.2s;
}

.auth-text-error {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: var(--red-color);
}

.profile_download {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  background: var(--yellow-color);
  font-family: 'Archivo';
  font-size: 24px;
  line-height: 26px;
  color: var(--dark-color);
  font-weight: 600;
}

.profile_download-button {
  width: 200px;
  height: 50px;
  background: #161C2D;
  border-radius: 8px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--white-color);
  transition: 0.2s;
}

.side-menu-checkbox {
  display: none;
}

.youtube__video {
  width: 450px;
  height: 300px;
}

@media (max-width: 768px) {
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: var(--dark-secondary-color);
  }

  .header_buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .introduction__heading {
    font-size: 32px;
    line-height: 36px;
  }

  .introduction-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .introduction__description {
    font-size: 18px;
    line-height: 20px;
  }

  .introduction__button {
    width: 100%;
    z-index: 2;
  }

  .introduction__robot {
    height: auto;
  }

  .introduction__robot img {
    width: 100%;
    max-width: 400px;
  }

  .button__login,
  .button__create-account {
    min-width: 100px;
    height: 30px;
    background-color: transparent;
    border-radius: 4px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--white-color);
    border: 1px solid var(--white-color);
  }

  .button__create-account {
    background: var(--yellow-color);
    border: 1px solid var(--yellow-color);
    color: var(--dark-color);
  }

  .youtube-wrapper {
    flex-direction: column;
    gap: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .youtube__video-container {
    width: 100%;
    height: auto;
  }

  .youtube__title {
    font-size: 32px;
  }

  .youtube__description {
    font-size: 16px;
  }

  .youtube__button {
    width: 100%;
  }

  .why-summarie__title {
    font-size: 32px;
  }

  .why-summarie__description {
    font-size: 16px;
  }

  .why-summarie__benefits {
    grid-template-columns: 1fr;
  }

  .benefit__number {
    font-size: 24px;
  }

  .benefit__text {
    font-size: 14px;
  }

  .plans-wrapper {
    padding: 40px 16px;
  }

  .plans__title {
    font-size: 32px;
  }

  .plans__description {
    font-size: 16px;
  }

  .plans__list {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }


  .plan-item-wrapper {
    width: 100%;
    max-width: 435px;
    background: var(--dark-secondary-color);
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
    border: 1px solid #8C8C8C;
  }

  .plan-item__name {
    font-family: 'Archivo';
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;
  }

  .plan-item__price {
    font-family: 'Archivo';
    font-weight: 700;
    font-size: 26px;
    color: var(--yellow-color);
  }

  .plan-item__description {
    font-family: 'Archivo';
    font-weight: 400;
    font-size: 16px;
    color: #8C8C8C;
    margin: 0;
  }

  .plan-item__underline {
    width: 100%;
    height: 1px;
    background: #8C8C8C;
    margin: 20px 0;
  }

  .plan-item__options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .plan-item__option {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .plan-item__option img {
    flex-shrink: 0;
  }

  .plan-item__option p {
    font-family: 'Archivo';
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0;
  }

  .plan-item__button {
    width: 163px;
    height: 50px;
    background: var(--yellow-color);
    border-radius: 8px;
    font-family: 'Archivo';
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    transition: background 0.2s;
  }

  .plan-item__button:hover {
    background: var(--yellow-color-hover);
  }

  .why-summarie__web-background {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer-wrapper {
    background: var(--dark-secondary-color);
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    text-align: center;
  }

  .footer__top-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    flex-wrap: wrap;
    gap: 16px;
  }

  .footer-top__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .footer__link {
    font-family: 'Archivo';
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;
    transition: color 0.2s;
  }

  .footer__top-info {
    flex-direction: column;

  }


  .footer-top__socials {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 24px;
  }

  .footer-top__socials img {
    width: 24px;
    height: 24px;
    transition: transform 0.2s;
  }

  .footer-top__socials img:hover {
    transform: scale(1.1);
    display: flex;
    align-items: center;
    justify-items: center;

  }

  .footer__bottom-copyright {
    font-family: 'Archivo';
    font-weight: 400;
    font-size: 14px;
    color: #8C8C8C;
  }

  .menu {
    display: none;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }

  .side-menu-checkbox:checked+.hamburger+.menu {
    display: flex;
    background-color: var(--dark-secondary-color);
    position: absolute;
    top: 40px;
    right: 0;
    border-radius: 8px;
    padding: 16px;
  }

  .hamburger {
    cursor: pointer;
    padding: 20px 20px;
  }

  .hamburger-line {
    background: var(--white-color);
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
  }

  .hamburger-line::before,
  .hamburger-line::after {
    background: var(--white-color);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .hamburger-line::before {
    top: 5px;
  }

  .hamburger-line::after {
    top: -5px;
  }

  .auth-page-wrapper {
    display: flex;
    width: 100%;
    min-height: 100vh;
  }

  .auth-page__left,
  .auth-page__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .auth-page__left {
    flex: 1;
    background-color: rgba(14, 14, 14, 0.85);
  }

  .auth-page__right {
    flex: 2;
    background-color: var(--dark-secondary-color);
  }

  .auth-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 400px;
  }

  .auth-input-block-wrapper {
    width: 100%;
  }

  .auth-input {
    width: 100%;
    padding: 10px;
  }

  .auth-google__button-wrapper {
    align-items: center;
    justify-items: center;
  }

  .auth-button,
  .auth-google-button {
    width: 100%;
    padding: 10px;
  }

  .auth-page-wrapper {
    flex-direction: column;
  }

  .auth-page__left {
    width: 100%;
    padding: 20px 0;

  }

  .auth-page__left::before {
    display: none;
  }

  .auth-page__right {
    width: 100%;
    padding: 20px;
  }

  .auth-form-wrapper {
    max-width: none;
  }

  .auth-title-block {
    padding: 10px 0 0 0;
    align-items: center;
  }

  .auth-page__back {
    margin: 0;
  }

  .profile_download {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .profile-info__header {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .profile-info__title {
    font-size: 16px;
  }

  .profile-info__change-plan {
    font-size: 16px;
    justify-self: start;
    align-items: start;
  }

  .profile__info-block {
    gap: 20px;
  }

  .robot__shadow {
    position: absolute;
    top: 120%;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 0px 0px 150px 180px rgba(255, 246, 28, 0.17);
    border-radius: 100%;
  }

  .youtube__video {
    width: 100%;
    height: 250px;
  }

  .youtube__video-block {
    width: 100%;
  }

  .checkout__form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px 16px;
    background: #161C2D;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }

  .checkout-right-side {
    width: 100%;
    background: #FFEE00;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: fit-content;
  }

  .stripe-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 16px;
    max-width: 100%;
    width: 100%;
  }

  .stripe-button__pay {
    width: 100%;
    height: 52px;
    background: #FFEE00;
    border-radius: 10px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #161C2D;
    transition: 0.2s;
    margin-top: 16px;
  }

  .checkout-right__plan-options {
    display: none;
  }

  .profile__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
    background: #161C2D;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }

  .profile_download {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background: var(--yellow-color);
    font-family: 'Archivo';
    font-size: 24px;
    line-height: 26px;
    color: var(--dark-color);
    font-weight: 600;
  }

  .profile_download-button {
    width: 100%;
    height: 50px;
    background: #161C2D;
    border-radius: 8px;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: var(--white-color);
    transition: 0.2s;
  }

  .profile__title {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
  }
}